import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriaService } from './../../../../services/admin/categoria.service';
import { QuestaoService } from './../../../../services/admin/questao.service';
import { Questionario } from './../../../../models/questionario.model';
import { Categoria } from './../../../../models/categoria.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-questao-edit',
  templateUrl: './questao-edit.component.html',
  styleUrls: ['./questao-edit.component.css']
})
export class QuestaoEditComponent implements OnInit {

  public questaoForm = this.fb.group({
    questionarioId: ['', [Validators.required]],
    categoriaId: ['', [Validators.required]],
    descricao: ['', [Validators.required]],
  });

  public categorias: Categoria[] = null;
  public questionarios: Questionario[] = null;
  public id: any = null;
  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private questaoService: QuestaoService,
    private categoriaService: CategoriaService,
    private questionarioService: QuestionarioService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showQuestao();
    this.readCategorias();
    this.readQuestionarios();
  }

  public onSubmit(): void {

    const formData = new FormData();
    formData.append('questionario_id', this.questaoForm.get('questionarioId').value);
    formData.append('categoria_id', this.questaoForm.get('categoriaId').value);
    formData.append('descricao', this.questaoForm.get('descricao').value);

    this.questaoService.update(formData, this.id).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/questoes']);
        this.questaoService.toastSuccess('Questão atualizada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.questaoService.toastError('Erro ao atualizar Questão. Sistema indisponível, Tente novamente !');
      }
    );
  }

  public showQuestao(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.questaoService.readById(this.id).subscribe(
      (res: any) => {
        this.updateForm(res);
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public updateForm(res): void {
    this.questaoForm.patchValue({
      id: res.data.id,
      categoriaId: res.data.categoria_id,
      questionarioId: res.data.questionario_id,
      descricao: res.data.descricao,
    });
  }

  public readCategorias(): void {
    this.categoriaService.read().subscribe(
      (res: Categoria[]) => {
        this.categorias = res;
      },
      (error: any) => {
        this.erro = error;
        this.categoriaService.toastError(
          'Erro ao buscar Categorias. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readQuestionarios(): void {
    this.questionarioService.read().subscribe(
      (res: Questionario[]) => {
        this.questionarios = res;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError(
          'Erro ao buscar Questionários. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/admin/questoes']);
  }

  get questionarioId() {
    return this.questaoForm.get('questionarioId');
  }
  get categoriaId() {
    return this.questaoForm.get('categoriaId');
  }
  get descricao() {
    return this.questaoForm.get('descricao');
  }
}
