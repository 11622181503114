import { Configuracao } from './../../../models/configuracao.model';
import { ConfiguracaoService } from './../../../services/admin/configuracao.service';
import { ContatoService } from './../../../services/site/contato.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css'],
})
export class ContatoComponent implements OnInit {
  public contatoForm = this.fb.group({
    nome: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    fone: [''],
    mensagem: ['', [Validators.required]],
  });

  public foneMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public erro: any = null;
  public configuracoes: Configuracao = null;
  public status = true;

  constructor(
    private fb: FormBuilder,
    private contatoService: ContatoService,
    private configService: ConfiguracaoService,
    private router: Router
  ) {}

  ngOnInit() {
    this.showConfiguracao();
  }

  public onSubmit(): void {

    this.validaForm();

    if (this.status === true) {
      const formData = new FormData();
      formData.append('nome', this.contatoForm.get('nome').value);
      formData.append('email', this.contatoForm.get('email').value);
      formData.append('telefone', this.contatoForm.get('fone').value);
      formData.append('mensagem', this.contatoForm.get('mensagem').value);

      this.contatoService.contato(formData).subscribe(
        (res: any) => {
          this.router.navigate(['/']);
          this.contatoService.toastSuccess('Mensagem enviada com sucesso para a Pecege !');
        },
        (error: any) => {
          this.erro = error;
          this.contatoForm.reset();
          this.router.navigate(['contato']);
        }
      );
    }
  }

  public validaForm(): void {
    if (
      this.contatoForm.value.nome === '' ||
      this.contatoForm.value.nome === null ||
      this.contatoForm.value.email === '' ||
      this.contatoForm.value.email === null ||
      this.contatoForm.value.mensagem === '' ||
      this.contatoForm.value.mensagem === null
    ) {
      this.contatoService.toastError('Necessário informar Nome, Email e Mensagem');
      this.status = false;
    }
  }

  public showConfiguracao(): void {
    this.configService.readById('1').subscribe(
      (res: any) => {
        this.configuracoes = res.data;
      },
      (error: any) => {
        this.erro = error;
        this.configService.toastError(
          'Erro ao buscar Configurações. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  get nome() { return this.contatoForm.get('nome'); }
  get email() { return this.contatoForm.get('email'); }
  get mensagem() { return this.contatoForm.get('mensagem'); }
}
