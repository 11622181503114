import { Configuracao } from './../../../models/configuracao.model';
import { ConfiguracaoService } from './../../../services/admin/configuracao.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  public configuracoes: Configuracao = null;
  public id: any = 1;
  public erro: any = null;
  public ano: any = Date.now();

  constructor(private configService: ConfiguracaoService) {}

  ngOnInit() {
    this.showConfiguracao();
  }

  public showConfiguracao(): void {
    this.configService.readById(this.id).subscribe(
      (res: any) => {
        this.configuracoes = res.data;
      },
      (error: any) => {
        this.erro = error;
        this.configService.toastError(
          'Erro ao buscar Configurações. Sistema indisponível, tente novamente !'
        );
      }
    );
  }
}
