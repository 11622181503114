import { AuthsiteService } from './../services/site/authsite.service';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthsiteGuard implements CanActivate {

  constructor(
    private authSiteService: AuthsiteService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  canActivate(): boolean {
    if (this.authSiteService.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      this.toastr.error('Você precisa estar logado para acessar essa área !');
      return false;
    }
  }
}
