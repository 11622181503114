import { Categoria } from './../../models/categoria.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoriaService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public create(categoria: any): Observable<Categoria> {
    const service = 'categorias';
    return this.http.post<Categoria>(this.baseUrlAdmin + service, categoria);
  }

  public read(): Observable<Categoria[]> {
    const service = 'categorias';
    return this.http.get<Categoria[]>(this.baseUrlAdmin + service);
  }

  public readById(id: string): Observable<Categoria> {
    const service = `${'categorias'}/${id}`;
    return this.http.get<Categoria>(this.baseUrlAdmin + service);
  }

  public update(categoria: any, id: any): Observable<Categoria> {
    const service = `${'categorias'}/${id}`;
    return this.http.put<Categoria>(this.baseUrlAdmin + service, categoria);
  }

  public delete(id: string): Observable<Categoria> {
    const service = `${'categorias'}/${id}`;
    return this.http.delete<Categoria>(this.baseUrlAdmin + service);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
