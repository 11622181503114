import { ActivatedRoute, Router } from '@angular/router';
import { ReguaService } from './../../../../services/admin/regua.service';
import { IdentificadorService } from './../../../../services/admin/identificador.service';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { GabaritoService } from './../../../../services/admin/gabarito.service';
import { Regua } from './../../../../models/regua.model';
import { Gabarito } from './../../../../models/gabarito.model';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-meus-questionarios-show',
  templateUrl: './meus-questionarios-show.component.html',
  styleUrls: ['./meus-questionarios-show.component.css']
})
export class MeusQuestionariosShowComponent implements OnInit {

  public identificador: any = null;
  public questionarioId: any = null;
  public questionarioNome: any = null;
  public dadosIdentificador: any = null;
  public gabaritos: Gabarito[] = null;
  public reguas: Regua[] = null;
  public pontos: any = null;
  public erro: any = null;
  public paginaAtual = 1;

  constructor(
    private gabaritoService: GabaritoService,
    private questionarioService: QuestionarioService,
    private identificadorService: IdentificadorService,
    private reguaService: ReguaService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showRespostas();
    this.readIdentificador();
    this.pontuacao();
    this.showQuestionario();
    this.readReguas();
  }

  public showRespostas(): void {

    this.identificador = this.route.snapshot.paramMap.get('identificador');
    this.questionarioId = this.route.snapshot.paramMap.get('questionario');

    this.gabaritoService.respostasQuestionario(this.identificador).subscribe(
      (res: Gabarito[]) => {
        this.gabaritos = res;
      },
      (error: any) => {
        this.erro = error;
        this.gabaritoService.toastError(
          'Erro ao buscar Respostas do questionário. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readIdentificador(): void {
    this.identificadorService.readByIdentificador(this.identificador).subscribe(
      (res: any) => {
        this.dadosIdentificador = res;
      },
      (error: any) => {
        this.erro = error;
        this.identificadorService.toastError(
          'Erro ao buscar Identificador do questionário. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public pontuacao(): void {
    this.gabaritoService.pontuacaoQuestionario(this.identificador).subscribe(
      (res: any) => {
        this.pontos = res;
      },
      (error: any) => {
        this.erro = error;
        this.gabaritoService.toastError(
          'Erro ao buscar pontuação do questionario respondido. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public showQuestionario(): void {
    this.questionarioService.readById(this.questionarioId).subscribe(
      (res: any) => {
        this.questionarioNome = res.data.nome;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError(
          'Erro ao buscar nome do questionario respondido. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readReguas(): void {
    this.reguaService.read().subscribe(
      (res: Regua[]) => {
        this.reguas = res;
      },
      (error: any) => {
        this.erro = error;
        this.reguaService.toastError('Erro ao buscar Réguas. Sistema indisponível, tente novamente !');
      }
    );
  }

  public modalDetalha(): void {
    $('#modalDetalha').modal();
  }
}
