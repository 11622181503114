import { GabaritoService } from './../../../../services/admin/gabarito.service';
import { Opcao } from './../../../../models/opcao.model';
import { QuestaoService } from './../../../../services/admin/questao.service';
import { Questao } from './../../../../models/questao.model';
import { ActivatedRoute, Router } from '@angular/router';
import { OpcaoService } from './../../../../services/admin/opcao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-opcao-edit',
  templateUrl: './opcao-edit.component.html',
  styleUrls: ['./opcao-edit.component.css']
})
export class OpcaoEditComponent implements OnInit {
  public opcaoForm = this.fb.group({
    pontuacao: ['', [Validators.required]],
    descricao: ['', [Validators.required]],
  });

  public id: any = null;
  public erro: any = null;
  public questao: Questao = null;
  public opcao: Opcao = null;
  public questaoId: any = null;
  public opcaoId: any = null;
  public contador: any = null;

  constructor(
    private fb: FormBuilder,
    private opcaoService: OpcaoService,
    private gabaritoService: GabaritoService,
    private questaoService: QuestaoService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showOpcao();
  }

  public showOpcao(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.opcaoService.readById(this.id).subscribe(
      (res: any) => {
        this.showQuestao(res.data.questao_id);
        this.updateForm(res);
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public showQuestao(id: any): void {
    this.questaoService.readById(id).subscribe(
      (res: any) => {
        this.questao = res;
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public onSubmit(): void {
    const formData = new FormData();
    formData.append('pontuacao', this.opcaoForm.get('pontuacao').value);
    formData.append('descricao', this.opcaoForm.get('descricao').value);

    this.opcaoService.update(formData, this.id).subscribe(
      (res: any) => {
        location.reload();
        this.opcaoService.toastSuccess('Opção atualizada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.opcaoService.toastError('Erro ao atualizar Opção. Sistema indisponível, Tente novamente !');
      }
    );
  }

  public updateForm(res): void {
    this.opcaoForm.patchValue({
      id: res.data.id,
      pontuacao: res.data.pontuacao,
      descricao: res.data.descricao,
    });
  }

  public modalShow(id: string): void {
    this.opcaoId = id;       // id a ser deletado
    this.showOpcaoModal(this.opcaoId);
    $('#modalShow').modal();
  }

  public showOpcaoModal(id): void {
    this.opcaoService.readById(id).subscribe(
      (res: Opcao) => {
        this.opcao = res;     // para mostrar no modal
      },
      (error: any) => {
        this.erro = error;
        this.opcaoService.toastError('Erro ao buscar dados da Opção. Sistema indisponível, tente novamente !');
      }
    );
  }

  public confirmDelete(id: string): void {

    this.gabaritoService.countOpcao(id).subscribe(
      (res: any) => {
        this.contador = res.data[0]['count(*)'];

        if (this.contador == 0) {

          this.opcaoService.delete(id).subscribe(
            (res: Opcao) => {
              this.router.navigate(['/admin/questoes']);
              this.opcaoService.toastSuccess('Opção apagada com sucesso !');
            },
            (error: any) => {
              this.erro = error;
              this.opcaoService.toastError('Erro ao apagar Opção. Tente novamente !');
            }
          );

        } else {
          this.gabaritoService.toastError('Já existem respostas para essa opção. Não é possível apagar !');
        }
      },
      (error: any) => {
        this.erro = error;
        this.gabaritoService.toastError('Erro inesperado. Tente novamente !');
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/admin/questoes']);
  }

  get pontuacao() { return this.opcaoForm.get('pontuacao'); }
  get descricao() { return this.opcaoForm.get('descricao'); }
}
