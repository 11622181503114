import {FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IdentificadorService } from './../../../../services/site/identificador.service';
import { GabaritoUserService } from './../../../../services/site/gabaritouser.service';
import { GabaritoService } from './../../../../services/admin/gabarito.service';
import { Questionario } from './../../../../models/questionario.model';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestaoService } from './../../../../services/site/questao.service';
import { Questao } from './../../../../models/questao.model';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-quest-read',
  templateUrl: './quest-read.component.html',
  styleUrls: ['./quest-read.component.css'],
})
export class QuestReadComponent implements OnInit {
  public identificadorForm = this.fb.group({
    orientador: ['', [Validators.required]],
    titulo: ['', [Validators.required]],
    curso: ['', [Validators.required]],
  });

  public op = new FormControl('');

  public questoes: Questao[] = null;
  public totalQuestoes: any = null;
  public questionario: Questionario = null;
  public userId: any = null;
  public erro: any = null;
  public id: any = null;
  public contador: any = null;
  public numeroQuestoes: any = null;
  public randomico: any = null;
  public min: any = null;
  public max: any = null;
  public questionarioAtivoId: any = null;
  public paginaAtual = 1;
  public selectedEntry: any = null;
  public guarda: any = null;
  public flag: any = null;

  // variaveis de registro da tabela gabaritos
  public questaoId = [];
  public opcaoId = [];
  public pontuacao = [];
  public marcacao = [];
  //

  constructor(
    private questaoService: QuestaoService,
    private questionarioService: QuestionarioService,
    private gabaritoService: GabaritoService,
    private gabaritouserService: GabaritoUserService,
    private identificadorService: IdentificadorService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // verifica se ID passado é de questionario ativo
    this.readQuestionarioInformado();
    this.contaNumeroQuestoesPorQquestionario();
    this.readQuestoes();
    this.contaNumeroRespostasPorQuest();
    this.getRandom();
  }

  public onSelectionChange(opcao): void {
    this.selectedEntry = Object.assign({}, this.selectedEntry, opcao);

    this.marcacao[this.paginaAtual - 1] = this.op.value;
    this.questaoId[this.paginaAtual - 1] = this.selectedEntry.questao_id;
    this.opcaoId[this.paginaAtual - 1] = this.selectedEntry.id;
    this.pontuacao[this.paginaAtual - 1] = this.selectedEntry.pontuacao;
  }

  public onSubmit(): void {
    for (let index = 0; index < this.paginaAtual; index++) {
      if (!this.marcacao[index]) {       //questaoId
        this.flag = 'questionarioInvalido';
      }
    }

    if (this.flag !== 'questionarioInvalido') {
      for (let index = 0; index < this.paginaAtual; index++) {
        this.onSubmitGabarito(index);
      }
      this.onSubmitIdentificador(); // grava dados da tabela identificador
    } else {
      this.gabaritouserService.toastError('Existe(m) questão(ões) não respondida(s). Respostas não enviadas !');
      this.flag = null;
    }
  }

  public onSubmitGabarito(index): void {
    const formData = new FormData();
    formData.append('identificador', this.randomico);
    formData.append('questionario_id', this.questionarioAtivoId);
    formData.append('questao_id', this.questaoId[index]);
    formData.append('opcao_id', this.opcaoId[index]);
    formData.append('pontuacao', this.pontuacao[index]);

    this.gabaritouserService.create(formData).subscribe(
      (res: any) => {},
      (error: any) => {
        this.erro = error;
        this.gabaritouserService.toastError(
          'Erro ao submeter respostas. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  // grava registro de identificador = identificador/orientador/titulo/curso
  public onSubmitIdentificador(): void {
    const formData = new FormData();
    formData.append('identificador', this.randomico);
    formData.append(
      'orientador',
      this.identificadorForm.get('orientador').value
    );
    formData.append('titulo', this.identificadorForm.get('titulo').value);
    formData.append('curso', this.identificadorForm.get('curso').value);

    this.identificadorService.create(formData).subscribe(
      (res: any) => {
        $('#modalConfirma').modal('hide');
        this.router.navigate(['/finalizou/' + this.randomico]);
        this.identificadorService.toastSuccess(
          'Respostas enviadas com sucesso !'
        );
      },
      (error: any) => {
        this.erro = error;
        this.identificadorService.toastError(
          'Erro ao submeter respostas. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public modalConfirmaEnvio(): void {
    $('#modalConfirma').modal();
  }

  public readQuestionarioInformado(): void {
    this.questionarioAtivoId = this.route.snapshot.paramMap.get(
      'questionarioAtivoId'
    );

    this.questionarioService.readById(this.questionarioAtivoId).subscribe(
      (res: Questionario) => {
        this.questionario = res;
      },
      (error: any) => {
        this.erro = error;
        this.handleError(this.erro);
      }
    );
  }

  public handleError(erro): void {
    if (erro.status === 500) {
      this.questionarioService.toastError(
        'Questionário não existe, verifique o endereço http no navegador'
      );
    } else {
      this.questionarioService.toastError(
        'Erro ao buscar dados do Questionário. Sistema indisponível, tente novamente !'
      );
    }
  }

  public readQuestoes(): void {
    this.questaoService.read().subscribe(
      (res: Questao[]) => {
        this.questoes = res;
        this.totalQuestoes = this.questoes.length;
      },
      (error: any) => {
        this.erro = error;
        this.questaoService.toastError(
          'Erro ao buscar Questões. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public contaNumeroRespostasPorQuest(): void {
    this.gabaritoService
      .contaRespostasPorQuestionario(this.questionarioAtivoId)
      .subscribe(
        (res: any) => {
          this.contador = res;
        },
        (error: any) => {
          this.gabaritoService.toastError(
            'Erro ao buscar dados do logado, tente novamente !'
          );
        }
      );
  }

  public contaNumeroQuestoesPorQquestionario(): void {
    this.questaoService
      .contaQuestoesPorQuestionario(this.questionarioAtivoId)
      .subscribe(
        (res: any) => {
          this.numeroQuestoes = res.data;
        },
        (error: any) => {
          this.erro = error;
          this.questaoService.toastError(
            'Erro ao buscar número de questões por questionario !'
          );
        }
      );
  }

  public getRandom() {
    this.min = Math.ceil(1);
    this.max = Math.floor(999999);
    this.randomico = Math.floor(Math.random() * (this.max - this.min)) + this.min;
  }

  public voltar(): void {
    this.paginaAtual = this.numeroQuestoes - 1;
  }

  get orientador() {
    return this.identificadorForm.get('orientador');
  }
  get titulo() {
    return this.identificadorForm.get('titulo');
  }
  get curso() {
    return this.identificadorForm.get('curso');
  }
}
