import { Router, ActivatedRoute } from '@angular/router';
import { ConfiguracaoService } from './../../../../services/admin/configuracao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-config-edit',
  templateUrl: './config-edit.component.html',
  styleUrls: ['./config-edit.component.css'],
})
export class ConfigEditComponent implements OnInit {

  public configForm = this.fb.group({
    email_contato: [''],
    endereco: [''],
    cidade: [''],
    cep: [''],
    tel_contato_1: [''],
    tel_contato_2: [''],
    tel_contato_3: [''],
    url_facebook: [''],
    url_instagram: [''],
    url_youtube: [''],
    url_linkedin: [''],
  });

  public id: any = null;
  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private configService: ConfiguracaoService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showConfiguracao();
  }

  public onSubmit(): void {

    const formData = new FormData();
    formData.append('email_contato', this.configForm.get('email_contato').value);
    formData.append('endereco', this.configForm.get('endereco').value);
    formData.append('cidade', this.configForm.get('cidade').value);
    formData.append('cep', this.configForm.get('cep').value);
    formData.append('tel_contato_1', this.configForm.get('tel_contato_1').value);
    formData.append('tel_contato_2', this.configForm.get('tel_contato_2').value);
    formData.append('tel_contato_3', this.configForm.get('tel_contato_3').value);
    formData.append('url_facebook', this.configForm.get('url_facebook').value);
    formData.append('url_instagram', this.configForm.get('url_instagram').value);
    formData.append('url_youtube', this.configForm.get('url_youtube').value);
    formData.append('url_linkedin', this.configForm.get('url_linkedin').value);

    this.configService.update(formData, this.id).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/configuracoes/edit/1']);
        this.configService.toastSuccess('Configuração atualizada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.configService.toastError('Erro ao atualizar Configuração. Sistema indisponível, Tente novamente !');
      }
    );
  }

  public showConfiguracao(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.configService.readById(this.id).subscribe(
      (res: any) => {
        this.updateForm(res);
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public updateForm(res): void {
    this.configForm.patchValue({
      id: res.data.id,
      email_contato: res.data.email_contato,
      endereco: res.data.endereco,
      cidade: res.data.cidade,
      cep: res.data.cep,
      tel_contato_1: res.data.tel_contato_1,
      tel_contato_2: res.data.tel_contato_2,
      tel_contato_3: res.data.tel_contato_3,
      url_facebook: res.data.url_facebook,
      url_instagram: res.data.url_instagram,
      url_youtube: res.data.url_youtube,
      url_linkedin: res.data.url_linkedin,
    });
  }

  public cancel(): void {
    this.router.navigate(['/admin/home']);
  }

  get url_facebook() { return this.configForm.get('url_facebook'); }
}
