import { User } from './../../../models/user.model';
import { Router } from '@angular/router';
import { AuthsiteService } from './../../../services/site/authsite.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
})
export class PerfilComponent implements OnInit {

  public user: User = null;

  public perfilForm = {
    username: null,
    password: null,
  };

  public passwordConfirmacao: null;

  public erro: any = null;

  constructor(
    private authSiteService: AuthsiteService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getProfile();
  }

  public onSubmit(): void {
    this.perfilForm.username = this.user.username;
    this.perfilForm.password = this.user.password;

    if (this.perfilForm.password !== this.passwordConfirmacao) {
      this.authSiteService.toastError('Senhas não conferem !');
      this.user.password = null;
      this.passwordConfirmacao = null;
    } else {
      this.authSiteService.update(this.perfilForm).subscribe(
        (res: any) => {
          this.router.navigate(['/']);
          this.authSiteService.logout();
          this.authSiteService.toastSuccess('Senha alterada com sucesso. Efetue novo login !');
        },
        (error: any) => {
          this.authSiteService.toastError('Erro ao atualizar perfil, tente novamente !');
        }
      );
    }
  }

  public getProfile(): void {
    this.authSiteService.profile().subscribe(
      (res: any) => {
        this.user = res;
      },
      (error: any) => {
        this.authSiteService.toastError('Erro ao buscar perfil, tente novamente !');
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/']);
  }
}
