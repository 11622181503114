import { QuestionarioService } from './../../../services/admin/questionario.service';
import { User } from '../../../models/user.model';
import { AuthsiteService } from '../../../services/site/authsite.service';
import { AuthadminService } from '../../../services/admin/authadmin.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent implements OnInit {
  public user: User = null;
  public questionarioAtivoId: any = null;
  public nome: any = [];
  public path: any = null;
  public erro: any = null;
  public url = null;

  constructor(
    public authAdminService: AuthadminService,
    public authSiteService: AuthsiteService,
    public questionarioService: QuestionarioService
  ) {
    this.authSiteService.profile().subscribe(
      (res: any) => {
        if (res.role == 1) {
          localStorage.removeItem('token');
        }
      },
      (error: any) => {
        localStorage.removeItem('token');
      }
    );
  }

  ngOnInit() {
    this.getProfile();
    this.readQuestionarioAtivo();
  }

  public getProfile(): void {
    if (this.authSiteService.loggedIn()) {
      this.authSiteService.profile().subscribe(
        (res: any) => {
          this.user = res;
          this.nome = this.user.username.split(' ');
        },
        (error: any) => {
          this.authSiteService.toastError(
            'Erro ao buscar dados do logado, tente novamente !'
          );
        }
      );
    }
  }

  public readQuestionarioAtivo(): void {
    this.questionarioService.questionarioAtivo().subscribe(
      (res: any) => {
        this.questionarioAtivoId = res.data.id;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError(
          'Erro ao buscar Questionário Ativo. Sistema indisponível, tente novamente !'
        );
      }
    );
  }
}
