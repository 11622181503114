import { ActivatedRoute, Router } from '@angular/router';
import { ReguaService } from './../../../../services/admin/regua.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regua-edit',
  templateUrl: './regua-edit.component.html',
  styleUrls: ['./regua-edit.component.css']
})
export class ReguaEditComponent implements OnInit {

  public reguaForm = this.fb.group({
    descricao: ['', [Validators.required, Validators.minLength(3)]],
    pontuacao_minima: ['', [Validators.required]],
    pontuacao_maxima: ['', [Validators.required]],
  });

  public id: any = null;
  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private reguaService: ReguaService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit() {
    this.showRegua();
  }

  public onSubmit(): void {

    const formData = new FormData();
    formData.append('descricao', this.reguaForm.get('descricao').value);
    formData.append('pontuacao_maxima', this.reguaForm.get('pontuacao_maxima').value);
    formData.append('pontuacao_minima', this.reguaForm.get('pontuacao_minima').value);

    this.reguaService.update(formData, this.id).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/reguas']);
        this.reguaService.toastSuccess('Régua atualizada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.reguaService.toastError('Erro ao atualizar Régua. Sistema indisponível, Tente novamente !');
      }
    );
  }

  public showRegua(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.reguaService.readById(this.id).subscribe(
      (res: any) => {
        this.updateForm(res);
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public updateForm(res): void {
    this.reguaForm.patchValue({
      id: res.data.id,
      descricao: res.data.descricao,
      pontuacao_minima: res.data.pontuacao_minima,
      pontuacao_maxima: res.data.pontuacao_maxima,
    });
  }

  public cancel(): void {
    this.router.navigate(['/admin/reguas']);
  }

  get descricao() { return this.reguaForm.get('descricao'); }

  get pontuacao_minima() { return this.reguaForm.get('pontuacao_minima'); }

  get pontuacao_maxima() { return this.reguaForm.get('pontuacao_maxima'); }

}
