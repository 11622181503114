import { Router } from '@angular/router';
import { AuthadminService } from './../../../../services/admin/authadmin.service';
import { User } from './../../../../models/user.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfil-admin-component',
  templateUrl: './perfil-admin.component.html',
  styleUrls: ['./perfil-admin.component.css'],
})
export class PerfilAdminComponent implements OnInit {
  public user: User = null;

  public perfilForm = {
    username: null,
    password: null,
  };

  public passwordConfirmacao: null;

  public erro: any = null;

  constructor(
    private authAdminService: AuthadminService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getProfile();
  }

  public onSubmit(): void {
    this.perfilForm.username = this.user.username;
    this.perfilForm.password = this.user.password;

    if (this.perfilForm.password !== this.passwordConfirmacao) {
      this.authAdminService.toastError('Senhas não conferem !');
      this.user.password = null;
      this.passwordConfirmacao = null;
    } else {
      this.authAdminService.update(this.perfilForm).subscribe(
        (res: any) => {
          this.router.navigate(['/admin/login']);
          this.authAdminService.logout();
          this.authAdminService.toastSuccess('Senha alterada com sucesso. Efetue novo login !');
        },
        (error: any) => {
          this.authAdminService.toastError('Erro ao atualizar perfil, tente novamente !');
        }
      );
    }
  }

  public getProfile(): void {
    this.authAdminService.profile().subscribe(
      (res: any) => {
        this.user = res;
      },
      (error: any) => {
        this.authAdminService.toastError('Erro ao buscar perfil do admin, tente novamente !');
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/admin/login']);
  }
}
