import { Router, ActivatedRoute } from '@angular/router';
import { CategoriaService } from './../../../../services/admin/categoria.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-categoria-edit',
  templateUrl: './categoria-edit.component.html',
  styleUrls: ['./categoria-edit.component.css'],
})
export class CategoriaEditComponent implements OnInit {
  public categoriaForm = this.fb.group({
    nome: ['', [Validators.required, Validators.minLength(3)]],
  });

  public id: any = null;
  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private categoriaService: CategoriaService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showCategoria();
  }

  public onSubmit(): void {

    const formData = new FormData();
    formData.append('nome', this.categoriaForm.get('nome').value);

    this.categoriaService.update(formData, this.id).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/categorias']);
        this.categoriaService.toastSuccess('Categoria atualizada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.categoriaService.toastError('Erro ao atualizar Categoria. Sistema indisponível, Tente novamente !');
      }
    );
  }

  public showCategoria(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.categoriaService.readById(this.id).subscribe(
      (res: any) => {
        this.updateForm(res);
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public updateForm(res): void {
    this.categoriaForm.patchValue({
      id: res.data.id,
      nome: res.data.nome,
    });
  }

  public cancel(): void {
    this.router.navigate(['/admin/categorias']);
  }

  get nome() { return this.categoriaForm.get('nome'); }
}
