import { AuthsiteService } from './../../../services/site/authsite.service';
import { User } from './../../../models/user.model';
import { Component, OnInit } from '@angular/core';
import { AuthadminService } from 'src/app/services/admin/authadmin.service';

@Component({
  selector: 'app-nav-menu-admin',
  templateUrl: './nav-menu-admin.component.html',
  styleUrls: ['./nav-menu-admin.component.css']
})
export class NavMenuAdminComponent implements OnInit {

  public user: User = null;
  public nome: any = [];
  public path: any = null;
  public erro: any = null;

  constructor(
    public authAdminService: AuthadminService,
    public authSiteService: AuthsiteService
  ) {
    this.authAdminService.profile().subscribe(
      (res: any) => {
        if (res.role == 2) {
          localStorage.removeItem('token');
        }
      },
      (error: any) => {
        localStorage.removeItem('token');
      }
    );
  }

  ngOnInit() {
    this.getProfileAdmin();
  }

  public getProfileAdmin(): void {
    if (this.authAdminService.loggedIn()) {
      this.authAdminService.profile().subscribe(
        (res: any) => {
          this.user = res;
          this.nome = this.user.username.split(' ');
        },
        (error: any) => {
          this.authAdminService.toastError(
            'Erro ao buscar dados do Admin logado, tente novamente !'
          );
        }
      );
    }
  }
}
