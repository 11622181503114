import { Router } from '@angular/router';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { Questionario } from './../../../../models/questionario.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questionario-read',
  templateUrl: './questionario-read.component.html',
  styleUrls: ['./questionario-read.component.css'],
})
export class QuestionarioReadComponent implements OnInit {
  public questionarios: Questionario[] = null;
  public erro: any = null;
  public id: any = null;
  public paginaAtual = 1;

  constructor(
    private questionarioService: QuestionarioService,
    private router: Router
  ) {}

  ngOnInit() {
    this.readQuestionarios();
  }

  public readQuestionarios(): void {
    this.questionarioService.read().subscribe(
      (res: Questionario[]) => {
        this.questionarios = res;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError(
          'Erro ao buscar Questionários. Sistema indisponível, tente novamente !'
        );
      }
    );
  }
}
