import { QuestaoService } from './../../../../services/admin/questao.service';
import { Questionario } from './../../../../models/questionario.model';
import { Categoria } from './../../../../models/categoria.model';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { CategoriaService } from './../../../../services/admin/categoria.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questao-create',
  templateUrl: './questao-create.component.html',
  styleUrls: ['./questao-create.component.css'],
})
export class QuestaoCreateComponent implements OnInit {
  public categorias: Categoria[] = null;
  public questionarios: Questionario[] = null;
  public erro: any = null;

  public questaoForm = this.fb.group({
    questionarioId: ['', [Validators.required]],
    categoriaId: ['', [Validators.required]],
    descricao: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private questaoService: QuestaoService,
    private categoriaService: CategoriaService,
    private questionarioService: QuestionarioService,
    private router: Router
  ) {}

  ngOnInit() {
    this.readCategorias();
    this.readQuestionarios();
  }

  public onSubmit(): void {
    const formData = new FormData();
    formData.append('questionario_id', this.questaoForm.get('questionarioId').value);
    formData.append('categoria_id', this.questaoForm.get('categoriaId').value);
    formData.append('descricao', this.questaoForm.get('descricao').value);

    this.questaoService.create(formData).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/questoes']);
        this.questaoService.toastSuccess('Cadastro da Questão efetuado com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.questaoService.toastError('Erro ao efetuar cadastro da Questão. Tente novamente !');
        this.questaoForm.reset();
      }
    );
  }

  public readCategorias(): void {
    this.categoriaService.read().subscribe(
      (res: Categoria[]) => {
        this.categorias = res;
      },
      (error: any) => {
        this.erro = error;
        this.categoriaService.toastError(
          'Erro ao buscar Categorias. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readQuestionarios(): void {
    this.questionarioService.read().subscribe(
      (res: Questionario[]) => {
        this.questionarios = res;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError(
          'Erro ao buscar Questionários. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/admin/questoes']);
  }

  get questionarioId() {
    return this.questaoForm.get('questionarioId');
  }
  get categoriaId() {
    return this.questaoForm.get('categoriaId');
  }
  get descricao() {
    return this.questaoForm.get('descricao');
  }
}
