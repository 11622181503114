import { FormBuilder } from '@angular/forms';
import { Questionario } from './../../../../models/questionario.model';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { QuestaoService } from './../../../../services/admin/questao.service';
import { Router } from '@angular/router';
import { Questao } from './../../../../models/questao.model';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-questao-read',
  templateUrl: './questao-read.component.html',
  styleUrls: ['./questao-read.component.css'],
})
export class QuestaoReadComponent implements OnInit {
  public questoes: Questao[] = null;
  public questionarios: Questionario[] = null;
  public erro: any = null;
  public id: any = null;
  public paginaAtual = 1;
  public questionarioId: any = null;

  public searchForm = this.fb.group({
    questionarioId: [''],
  });

  constructor(
    private fb: FormBuilder,
    private questaoService: QuestaoService,
    private questionarioService: QuestionarioService,
    private router: Router
  ) {}

  ngOnInit() {
    this.readQuestoes();
    this.readQuestionarios();
  }

  public readQuestoes(): void {
    this.questaoService.read().subscribe(
      (res: Questao[]) => {
        this.questoes = res;
      },
      (error: any) => {
        this.erro = error;
        this.questaoService.toastError(
          'Erro ao buscar Questões. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readQuestionarios(): void {
    this.questionarioService.read().subscribe(
      (res: Questionario[]) => {
        this.questionarios = res;
      },
      (error: any) => {
        this.erro = error;
        this.questaoService.toastError(
          'Erro ao buscar Questionários. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public onSubmitSearch(): void {
    this.questionarioId = this.searchForm.get('questionarioId').value;

    this.questaoService.readQuestoesQuestionario(this.questionarioId).subscribe(
      (res: Questao[]) => {
        this.questoes = res;
      },
      (error: any) => {
        this.erro = error;
        this.questaoService.toastError(
          'Erro ao buscar Questões do questionário. Sistema indisponível, tente novamente !'
        );
      }
    );
  }
}
