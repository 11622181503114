import { Router } from '@angular/router';
import { InscricaoService } from './../../../../services/admin/inscricao.service';
import { User } from './../../../../models/user.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inscricao-read',
  templateUrl: './inscricao-read.component.html',
  styleUrls: ['./inscricao-read.component.css'],
})
export class InscricaoReadComponent implements OnInit {
  public inscricoes: User[] = null;
  public erro: any = null;
  public id: any = null;
  public paginaAtual = 1;

  public form = {
    search: null,
  };

  constructor(
    private inscricaoService: InscricaoService,
    private router: Router
  ) {}

  ngOnInit() {
    this.readInscricoes();
  }

  public readInscricoes(): void {
    this.inscricaoService.read().subscribe(
      (res: User[]) => {
        this.inscricoes = res;
      },
      (error: any) => {
        this.erro = error;
        this.inscricaoService.toastError(
          'Erro ao buscar Inscritos. Sistema indisponível, tente novamente !'
        );
      }
    );
  }
}
