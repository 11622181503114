import { AuthadminGuard } from './guard/authadmin.guard';
import { AuthsiteGuard } from './guard/authsite.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './services/site/token-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxPrintModule } from 'ngx-print';
import { CustomFormsModule } from 'ng2-validation';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/template/nav-menu/nav-menu.component';
import { HomeSiteComponent } from './views/site/home/home-site/home-site.component';
import { FooterComponent } from './components/template/footer/footer.component';
import { QuestReadComponent } from './views/site/questionario/quest-read/quest-read.component';
import { LoginSiteComponent } from './views/site/login/login-site/login-site.component';
import { LoginAdminComponent } from './views/admin/login/login-admin/login-admin.component';
import { HomeAdminComponent } from './views/admin/home/home-admin/home-admin.component';
import { ContatoComponent } from './views/site/contato/contato.component';
import { RegistroComponent } from './views/site/registro/registro.component';
import { PerfilComponent } from './views/site/perfil/perfil.component';
import { ResponseresetComponent } from './views/site/password/responsereset/responsereset.component';
import { ResponseresetAdminComponent } from './views/admin/password/responsereset-admin/responsereset-admin.component';
import { CategoriaReadComponent } from './views/admin/categoria/categoria-read/categoria-read.component';
import { CategoriaCreateComponent } from './views/admin/categoria/categoria-create/categoria-create.component';
import { CategoriaEditComponent } from './views/admin/categoria/categoria-edit/categoria-edit.component';
import { AdministradorReadComponent } from './views/admin/administrador/administrador-read/administrador-read.component';
import { AdministradorCreateComponent } from './views/admin/administrador/administrador-create/administrador-create.component';
import { QuestionarioCreateComponent } from './views/admin/questionario/questionario-create/questionario-create.component';
import { QuestionarioEditComponent } from './views/admin/questionario/questionario-edit/questionario-edit.component';
import { QuestionarioReadComponent } from './views/admin/questionario/questionario-read/questionario-read.component';
import { QuestaoReadComponent } from './views/admin/questao/questao-read/questao-read.component';
import { QuestaoCreateComponent } from './views/admin/questao/questao-create/questao-create.component';
import { QuestaoEditComponent } from './views/admin/questao/questao-edit/questao-edit.component';
import { OpcaoEditComponent } from './views/admin/opcao/opcao-edit/opcao-edit.component';
import { OpcaoCreateComponent } from './views/admin/opcao/opcao-create/opcao-create.component';
import { ConfigEditComponent } from './views/admin/configuracao/config-edit/config-edit.component';
import { InscricaoReadComponent } from './views/admin/inscricao/inscricao-read/inscricao-read.component';
import { PerfilAdminComponent } from './views/admin/perfil/perfil-admin/perfil-admin.component';
import { ReguaCreateComponent } from './views/admin/regua/regua-create/regua-create.component';
import { ReguaEditComponent } from './views/admin/regua/regua-edit/regua-edit.component';
import { ReguaReadComponent } from './views/admin/regua/regua-read/regua-read.component';
import { QuestRespondidoReadComponent } from './views/admin/inscricao/quest-respondido-read/quest-respondido-read.component';
import { RespostasReadComponent } from './views/admin/inscricao/respostas-read/respostas-read.component';
import { QuestFinalizouComponent } from './views/site/questionario/quest-finalizou/quest-finalizou.component';
import { MeusQuestionariosReadComponent } from './views/site/meus-questionarios/meus-questionarios-read/meus-questionarios-read.component';
import { MeusQuestionariosShowComponent } from './views/site/meus-questionarios/meus-questionarios-show/meus-questionarios-show.component';
import { RandomPipe } from './pipes/random.pipe';
import { NavMenuAdminComponent } from './components/template/nav-menu-admin/nav-menu-admin.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeSiteComponent,
    FooterComponent,
    QuestReadComponent,
    NavMenuComponent,
    LoginSiteComponent,
    LoginAdminComponent,
    HomeAdminComponent,
    ContatoComponent,
    RegistroComponent,
    PerfilComponent,
    ResponseresetComponent,
    ResponseresetAdminComponent,
    CategoriaReadComponent,
    CategoriaCreateComponent,
    CategoriaEditComponent,
    AdministradorReadComponent,
    AdministradorCreateComponent,
    QuestionarioCreateComponent,
    QuestionarioEditComponent,
    QuestionarioReadComponent,
    QuestaoReadComponent,
    QuestaoCreateComponent,
    QuestaoEditComponent,
    OpcaoEditComponent,
    OpcaoCreateComponent,
    ConfigEditComponent,
    InscricaoReadComponent,
    PerfilAdminComponent,
    ReguaCreateComponent,
    ReguaEditComponent,
    ReguaReadComponent,
    QuestRespondidoReadComponent,
    RespostasReadComponent,
    QuestFinalizouComponent,
    MeusQuestionariosReadComponent,
    MeusQuestionariosShowComponent,
    RandomPipe,
    NavMenuAdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      progressBar: true,
      preventDuplicates: false,
    }),
    ReactiveFormsModule,
    TextMaskModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    CustomFormsModule

  ],
  providers: [
    AuthsiteGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    AuthadminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
