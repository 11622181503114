import { Configuracao } from './../../models/configuracao.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfiguracaoService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public readById(id: string): Observable<Configuracao> {
    const service = `${'configuracoes'}/${id}`;
    return this.http.get<Configuracao>(this.baseUrlAdmin + service);
  }

  public update(configuracao: any, id: any): Observable<Configuracao> {
    const service = `${'configuracoes'}/${id}`;
    return this.http.put<Configuracao>(
      this.baseUrlAdmin + service,
      configuracao
    );
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
