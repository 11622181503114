import { Router } from '@angular/router';
import { CategoriaService } from './../../../../services/admin/categoria.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-categoria-create',
  templateUrl: './categoria-create.component.html',
  styleUrls: ['./categoria-create.component.css'],
})
export class CategoriaCreateComponent implements OnInit {
  public categoriaForm = this.fb.group({
    nome: ['', [Validators.required, Validators.minLength(3)]],
  });

  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private categoriaService: CategoriaService,
    private router: Router
  ) {}

  ngOnInit() {}

  public onSubmit(): void {
    const formData = new FormData();
    formData.append('nome', this.categoriaForm.get('nome').value);

    this.categoriaService.create(formData).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/categorias']);
        this.categoriaService.toastSuccess('Cadastro de categoria efetuado com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.categoriaService.toastError('Erro ao efetuar cadastro da categoria. Tente novamente !');
        this.categoriaForm.reset();
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/admin/categorias']);
  }

  get nome() { return this.categoriaForm.get('nome'); }
}
