import { Questionario } from './../../models/questionario.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuestionarioService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public create(questionario: any): Observable<Questionario> {
    const service = 'questionarios';
    return this.http.post<Questionario>(this.baseUrlAdmin + service, questionario);
  }

  public read(): Observable<Questionario[]> {
    const service = 'questionarios';
    return this.http.get<Questionario[]>(this.baseUrlAdmin + service);
  }

  public readById(id: string): Observable<Questionario> {
    const service = `${'questionarios'}/${id}`;
    return this.http.get<Questionario>(this.baseUrlAdmin + service);
  }

  public update(questionario: any, id: any): Observable<Questionario> {
    const service = `${'questionarios'}/${id}`;
    return this.http.put<Questionario>(this.baseUrlAdmin + service, questionario);
  }

  public delete(id: string): Observable<Questionario> {
    const service = `${'questionarios'}/${id}`;
    return this.http.delete<Questionario>(this.baseUrlAdmin + service);
  }

  public questionarioAtivo(): Observable<Questionario[]> {
    const service = 'questionarioAtivo';
    return this.http.get<Questionario[]>(this.baseUrlAdmin + service);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
