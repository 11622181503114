import { Opcao } from './../../../../models/opcao.model';
import { Questao } from './../../../../models/questao.model';
import { QuestaoService } from './../../../../services/admin/questao.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OpcaoService } from './../../../../services/admin/opcao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-opcao-create',
  templateUrl: './opcao-create.component.html',
  styleUrls: ['./opcao-create.component.css'],
})
export class OpcaoCreateComponent implements OnInit {

  public opcaoForm = this.fb.group({
    pontuacao: ['', [Validators.required]],
    descricao: ['', [Validators.required]],
  });

  public erro: any = null;
  public questaoId: any = null;
  public opcaoId: any = null;
  public questao: Questao = null;
  public opcao: Opcao = null;

  constructor(
    private fb: FormBuilder,
    private opcaoService: OpcaoService,
    private questaoService: QuestaoService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showQuestao();
  }

  public showQuestao(): void {
    this.questaoId = this.route.snapshot.paramMap.get('id');

    this.questaoService.readById(this.questaoId).subscribe(
      (res: any) => {
        this.questao = res;
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public onSubmit(): void {
    const formData = new FormData();
    formData.append('questao_id', this.questaoId);
    formData.append('pontuacao', this.opcaoForm.get('pontuacao').value);
    formData.append('descricao', this.opcaoForm.get('descricao').value);

    this.opcaoService.create(formData).subscribe(
      (res: any) => {
        location.reload();
        this.opcaoService.toastSuccess('Cadastro de opção da questão efetuado com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.opcaoService.toastError('Erro ao efetuar cadastro da opção da questão. Tente novamente !');
        this.opcaoForm.reset();
      }
    );
  }

  public modalShow(id: string): void {
    this.opcaoId = id;       // id a ser deletado
    this.showOpcao(this.opcaoId);
    $('#modalShow').modal();
  }

  public showOpcao(id): void {
    this.opcaoService.readById(id).subscribe(
      (res: Opcao) => {
        this.opcao = res;     // para mostrar no modal
      },
      (error: any) => {
        this.erro = error;
        this.opcaoService.toastError('Erro ao buscar dados da Opção. Sistema indisponível, tente novamente !');
      }
    );
  }

  public confirmDelete(id: string): void {
    this.opcaoService.delete(id).subscribe(
      (res: Opcao) => {
        location.reload();
        this.opcaoService.toastSuccess('Opção apagada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.opcaoService.toastError('Erro ao apagar Opção. Tente novamente !');
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/admin/questoes']);
  }

  get pontuacao() { return this.opcaoForm.get('pontuacao'); }
  get descricao() { return this.opcaoForm.get('descricao'); }
}
