import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  baseUrlSite = environment.baseUrlSite;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  public contato(contato: any): Observable<any> {
    const service = 'contato';
    return this.http.post<any>(this.baseUrlSite + service, contato);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
