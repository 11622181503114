import { ForgotpasswordService } from './../../../../services/admin/forgotpassword.service';
import { AuthadminService } from './../../../../services/admin/authadmin.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.css']
})
export class LoginAdminComponent implements OnInit {
  public loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  public recuperarSenhaform = {
    email: null,
  };

  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private authAdminService: AuthadminService,
    private forgotPasswordService: ForgotpasswordService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  public login(): void {
    this.authAdminService.login(this.loginForm.value).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token.token);
        this.router.navigate(['/admin/home']);
        this.authAdminService.toastSuccess('Login efetuado com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.handleError(this.erro);
        this.loginForm.reset();
        this.router.navigate(['/admin/login']);
      }
    );
  }

  public handleError(erro): void {
    if (erro.status === 401) {
      this.authAdminService.toastError('Email não encontrado em nossa base de dados !');
    } else {
      this.authAdminService.toastError('Email/Senha não conferem !');
    }
  }

  public recuperarSenha(): void {
    if (this.recuperarSenhaform.email === '' || this.recuperarSenhaform.email === null) {
      this.authAdminService.toastError('Necessário informar o email !');
    } else {

      this.forgotPasswordService.forgotPassword(this.recuperarSenhaform).subscribe(
        (res: any) => {
          this.router.navigate(['/admin/login']);
          this.forgotPasswordService.toastSuccess('Email de recuperação de senha enviado com sucesso !');
        },
        (error: any) => {
          this.erro = error;
          if (this.erro.status === 401) {
            this.forgotPasswordService.toastError('Email não encontrado em nossa base de dados !');
          } else {
            this.forgotPasswordService.toastError('Erro ao enviar email de recuperação de senha, tente novamente !');
          }
          this.router.navigate(['/admin/login']);
        }
      );
    }
  }
}
