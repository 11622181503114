import { ForgotpasswordService } from './../../../../services/site/forgotpassword.service';
import { AuthsiteService } from './../../../../services/site/authsite.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-site',
  templateUrl: './login-site.component.html',
  styleUrls: ['./login-site.component.css'],
})
export class LoginSiteComponent implements OnInit {
  public loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  public recuperarSenhaform = {
    email: null,
  };

  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private authSiteService: AuthsiteService,
    private forgotPasswordService: ForgotpasswordService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const token = params['token'];

      if (this.authSiteService.loggedIn && token !== localStorage.getItem('@moveTCC:token')) {
        return this.login(token);
      }

      if (this.authSiteService.loggedIn) {
        return this.router.navigate(['/home']);
      }

      return this.login(token);
    });
  }

  public login(token): void {
    this.authSiteService.login(token).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token.token);
        localStorage.setItem('@moveTCC:token', token)
        this.router.navigate(['/home']);
        this.authSiteService.toastSuccess('Login efetuado com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.handleError(this.erro);
        this.router.navigate(['/login']);
      }
    );
  }

  public handleError(erro): void {
    this.authSiteService.toastError('Não foi possível realizar login no momento!');
  }

  public recuperarSenha(): void {
    if (this.recuperarSenhaform.email === '' || this.recuperarSenhaform.email === null) {
      this.authSiteService.toastError('Necessário informar o email !');
    } else {
      this.forgotPasswordService.forgotPassword(this.recuperarSenhaform).subscribe(
        (res: any) => {
          this.router.navigate(['/']);
          this.forgotPasswordService.toastSuccess('Email de recuperação de senha enviado com sucesso !');
        },
        (error: any) => {
          this.erro = error;
          if (this.erro.status === 401) {
            this.forgotPasswordService.toastError('Email não encontrado em nossa base de dados !');
          } else {
            this.forgotPasswordService.toastError('Erro ao enviar email de recuperação de senha, tente novamente !');
          }
          this.router.navigate(['/login']);
        }
      );
    }
  }
}
