import { ActivatedRoute, Router } from '@angular/router';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-questionario-edit',
  templateUrl: './questionario-edit.component.html',
  styleUrls: ['./questionario-edit.component.css'],
})
export class QuestionarioEditComponent implements OnInit {
  public questionarioForm = this.fb.group({
    nome: ['', [Validators.required, Validators.minLength(3)]],
    ativacao: ['', [Validators.required]],
    descricao: ['', [Validators.required]],
    qtd_respostas: ['', [Validators.required]],
  });

  public id: any = null;
  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private questionarioService: QuestionarioService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showCategoria();
  }

  public onSubmit(): void {
    const formData = new FormData();
    formData.append('nome', this.questionarioForm.get('nome').value);
    formData.append('ativacao', this.questionarioForm.get('ativacao').value);
    formData.append('descricao', this.questionarioForm.get('descricao').value);
    formData.append('qtd_respostas', this.questionarioForm.get('qtd_respostas').value);

    this.questionarioService.update(formData, this.id).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/questionarios']);
        this.questionarioService.toastSuccess('Questionário atualizado com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.handleError(this.erro);
      }
    );
  }

  public handleError(erro): void {
    if (erro.status === 400) {
      this.questionarioService.toastError(
        'Não pode haver 2 questionários ativos. Desative o outro para cadastrar um novo questionário ativo'
      );
    } else {
      this.questionarioService.toastError('Erro ao atualizar Questionário. Sistema indisponível, tente novamente !');
    }
  }

  public showCategoria(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.questionarioService.readById(this.id).subscribe(
      (res: any) => {
        this.updateForm(res);
      },
      (error: any) => {
        this.erro = error;
      }
    );
  }

  public updateForm(res): void {
    this.questionarioForm.patchValue({
      id: res.data.id,
      nome: res.data.nome,
      ativacao: res.data.ativacao,
      descricao: res.data.descricao,
      qtd_respostas: res.data.qtd_respostas,
    });
  }

  public cancel(): void {
    this.router.navigate(['/admin/questionarios']);
  }

  get nome() {
    return this.questionarioForm.get('nome');
  }

  get ativacao() {
    return this.questionarioForm.get('ativacao');
  }

  get descricao() {
    return this.questionarioForm.get('descricao');
  }

  get qtd_respostas() {
    return this.questionarioForm.get('qtd_respostas');
  }
}
