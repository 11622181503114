import { Identificador } from './../../../../models/identificador.model';
import { IdentificadorService } from './../../../../services/admin/identificador.service';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { Questionario } from './../../../../models/questionario.model';
import { User } from './../../../../models/user.model';
import { UserService } from './../../../../services/admin/user.service';
import { GabaritoService } from './../../../../services/admin/gabarito.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quest-respondido-read',
  templateUrl: './quest-respondido-read.component.html',
  styleUrls: ['./quest-respondido-read.component.css'],
})
export class QuestRespondidoReadComponent implements OnInit {
  public id: any = null;
  public erro: any = null;
  public questionariosRespondidos: any = [];
  public pontuacoesTodosQuests: any = [];
  public quests: Questionario[] = null;
  public identificadores: Identificador[] = null;
  public user: User = null;
  public paginaAtual = 1;

  constructor(
    private gabaritoService: GabaritoService,
    private questionarioService: QuestionarioService,
    private identificadorService: IdentificadorService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.showQuestionariosAluno();
    this.inscrito();
    this.readQuestionarios();
    this.readIdentificadores();
    this.pontuacoesTodas();
  }

  public showQuestionariosAluno(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.gabaritoService.questionariosRespondidos(this.id).subscribe(
      (res: any[]) => {
        this.questionariosRespondidos = res;
      },
      (error: any) => {
        this.erro = error;
        this.gabaritoService.toastError(
          'Erro ao buscar Questionários respondidos do usuário. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public inscrito(): void {
    this.userService.readById(this.id).subscribe(
      (res: User) => {
        this.user = res;
      },
      (error: any) => {
        this.erro = error;
        this.userService.toastError(
          'Erro ao buscar dados do inscrito. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readQuestionarios(): void {
    this.questionarioService.read().subscribe(
      (res: Questionario[]) => {
        this.quests = res;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError(
          'Erro ao buscar Questionários. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readIdentificadores(): void {
    this.identificadorService.read().subscribe(
      (res: any) => {
        this.identificadores = res;
      },
      (error: any) => {
        this.erro = error;
        this.identificadorService.toastError(
          'Erro ao buscar Identificadores. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public pontuacoesTodas(): void {

    this.gabaritoService.pontuacaoTodosQuestionarios().subscribe(
      (res: any[]) => {
        this.pontuacoesTodosQuests = res;
      },
      (error: any) => {
        this.erro = error;
        this.gabaritoService.toastError(
          'Erro ao buscar pontuações de questionários. Sistema indisponível, tente novamente !'
        );
      }
    );
  }
}
