import { Opcao } from './../../models/opcao.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpcaoService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public create(opcao: any): Observable<Opcao> {
    const service = 'opcoes';
    return this.http.post<Opcao>(this.baseUrlAdmin + service, opcao);
  }

  public read(): Observable<Opcao[]> {
    const service = 'opcoes';
    return this.http.get<Opcao[]>(this.baseUrlAdmin + service);
  }

  public readById(id: string): Observable<Opcao> {
    const service = `${'opcoes'}/${id}`;
    return this.http.get<Opcao>(this.baseUrlAdmin + service);
  }

  public update(opcao: any, id: any): Observable<Opcao> {
    const service = `${'opcoes'}/${id}`;
    return this.http.put<Opcao>(this.baseUrlAdmin + service, opcao);
  }

  public delete(id: string): Observable<Opcao> {
    const service = `${'opcoes'}/${id}`;
    return this.http.delete<Opcao>(this.baseUrlAdmin + service);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
