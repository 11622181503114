import { Router } from '@angular/router';
import { UserService } from './../../../../services/admin/user.service';
import { User } from './../../../../models/user.model';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-administrador-read',
  templateUrl: './administrador-read.component.html',
  styleUrls: ['./administrador-read.component.css']
})
export class AdministradorReadComponent implements OnInit {

  public administradores: User[] = null;
  public erro: any = null;
  public id: any = null;
  public nomeAdmin: any = null;
  public paginaAtual = 1;

  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.readAdministradores();
  }

  public readAdministradores(): void {
    this.userService.read().subscribe(
      (res: User[]) => {
        this.administradores = res;
      },
      (error: any) => {
        this.erro = error;
        this.userService.toastError('Erro ao buscar Administradores. Sistema indisponível, tente novamente !');
      }
    );
  }
  public modalDelete(id: string): void {
    this.id = id; // id a ser deletado
    this.showAdministrador(this.id);
    $('#modalDelete').modal()
  }

  public showAdministrador(id): void {
    this.userService.readById(id).subscribe(
      (res: User) => {
        this.nomeAdmin = res.username;     // para mostrar no modal
      },
      (error: any) => {
        this.erro = error;
        this.userService.toastError('Erro ao buscar Administrador. Sistema indisponível, tente novamente !');
      }
    );
  }

  public confirmDelete(id: string): void {
    this.userService.delete(id).subscribe(
      (res: User) => {
        location.reload();
        this.userService.toastSuccess('Administrador apagado com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.userService.toastError('Erro ao apagar Administrador. Sistema indisponível, tente novamente !');
      }
    );
  }
}
