import { ForgotpasswordService } from './../../../../services/site/forgotpassword.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-responsereset',
  templateUrl: './responsereset.component.html',
  styleUrls: ['./responsereset.component.css'],
})
export class ResponseresetComponent implements OnInit {
  public redefinirSenhaForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    passwordConfirmacao: ['', [Validators.required, Validators.minLength(6)]],
  });

  public token: any = null;
  public erro: any = null;

  constructor(
    private fb: FormBuilder,

    private forgotPasswordService: ForgotpasswordService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
    });
  }

  ngOnInit() {

  }

  public redefinir(): void {

    if (this.redefinirSenhaForm.value.password !== this.redefinirSenhaForm.value.passwordConfirmacao) {
      this.forgotPasswordService.toastError('Senhas devem ser iguais !');
    } else {
      const formData = new FormData();
      formData.append('password', this.redefinirSenhaForm.get('password').value);
      formData.append('token', this.token);

      this.forgotPasswordService.resetPassword(formData).subscribe(
        (res: any) => {
          this.router.navigate(['/login']);
          this.forgotPasswordService.toastSuccess('Senha alterada com sucesso !');
        },
        (error: any) => {
          this.erro = error;
          this.forgotPasswordService.toastError('Erro ao alterar senha. Solicite nova recuperação de senha !');
        }
      );
    }
  }
}
