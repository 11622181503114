import { User } from './../../models/user.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthadminService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  public login(dados): Observable<any> {
    const service = 'login';
    return this.http.post<any>(this.baseUrlAdmin + service, dados);
  }

  public loggedIn() {
    return !!localStorage.getItem('token');
  }

  public logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/admin/login']);
    this.toastSuccess('Logout efetuado com sucesso !');
  }

  public profile(): Observable<any> {
    const service = 'profile';
    return this.http.get<any>(this.baseUrlAdmin + service);
  }

  public getToken() {
    return localStorage.getItem('token');
  }

  public update(user: any): Observable<User> {
    const service = 'profile/edit';
    return this.http.put<User>(this.baseUrlAdmin + service, user);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
