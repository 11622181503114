import { Questao } from './../../models/questao.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuestaoService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public create(questao: any): Observable<Questao> {
    const service = 'questoes';
    return this.http.post<Questao>(this.baseUrlAdmin + service, questao);
  }

  public read(): Observable<Questao[]> {
    const service = 'questoes';
    return this.http.get<Questao[]>(this.baseUrlAdmin + service);
  }

  public readById(id: string): Observable<Questao> {
    const service = `${'questoes'}/${id}`;
    return this.http.get<Questao>(this.baseUrlAdmin + service);
  }

  public update(questao: any, id: any): Observable<Questao> {
    const service = `${'questoes'}/${id}`;
    return this.http.put<Questao>(this.baseUrlAdmin + service, questao);
  }

  public delete(id: string): Observable<Questao> {
    const service = `${'questoes'}/${id}`;
    return this.http.delete<Questao>(this.baseUrlAdmin + service);
  }

  public readQuestoesQuestionario(id: string): Observable<Questao[]> {
    const service = `${'questoes/questionario'}/${id}`;
    return this.http.get<Questao[]>(this.baseUrlAdmin + service);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
