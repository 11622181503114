import { IdentificadorService } from './../../../../services/admin/identificador.service';
import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { GabaritoService } from './../../../../services/admin/gabarito.service';
import { Identificador } from './../../../../models/identificador.model';
import { Questionario } from './../../../../models/questionario.model';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meus-questionarios',
  templateUrl: './meus-questionarios-read.component.html',
  styleUrls: ['./meus-questionarios-read.component.css'],
})
export class MeusQuestionariosReadComponent implements OnInit {

  public id: any = null;
  public questionariosRespondidos: any = [];
  public quests: Questionario[] = null;
  public identificadores: Identificador[] = null;
  public erro: any = null;

  constructor(
    private gabaritoService: GabaritoService,
    private questionarioService: QuestionarioService,
    private identificadorService: IdentificadorService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.showQuestionariosAluno();
    this.readQuestionarios();
    this.readIdentificadores();
  }

  public showQuestionariosAluno(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.gabaritoService.questionariosRespondidos(this.id).subscribe(
      (res: any[]) => {
        this.questionariosRespondidos = res;
      },
      (error: any) => {
        this.erro = error;
        this.gabaritoService.toastError(
          'Erro ao buscar Questionários respondidos do logado. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readQuestionarios(): void {
    this.questionarioService.read().subscribe(
      (res: Questionario[]) => {
        this.quests = res;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError(
          'Erro ao buscar Questionários. Sistema indisponível, tente novamente !'
        );
      }
    );
  }

  public readIdentificadores(): void {
    this.identificadorService.read().subscribe(
      (res: any) => {
        this.identificadores = res;
      },
      (error: any) => {
        this.erro = error;
        this.identificadorService.toastError(
          'Erro ao buscar Identificadores. Sistema indisponível, tente novamente !'
        );
      }
    );
  }
}
