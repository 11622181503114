import { MeusQuestionariosShowComponent } from './views/site/meus-questionarios/meus-questionarios-show/meus-questionarios-show.component';
import { MeusQuestionariosReadComponent } from './views/site/meus-questionarios/meus-questionarios-read/meus-questionarios-read.component';
import { QuestFinalizouComponent } from './views/site/questionario/quest-finalizou/quest-finalizou.component';
import { RespostasReadComponent } from './views/admin/inscricao/respostas-read/respostas-read.component';
import { ReguaEditComponent } from './views/admin/regua/regua-edit/regua-edit.component';
import { ReguaCreateComponent } from './views/admin/regua/regua-create/regua-create.component';
import { ReguaReadComponent } from './views/admin/regua/regua-read/regua-read.component';
import { PerfilAdminComponent } from './views/admin/perfil/perfil-admin/perfil-admin.component';
import { QuestRespondidoReadComponent } from './views/admin/inscricao/quest-respondido-read/quest-respondido-read.component';
import { InscricaoReadComponent } from './views/admin/inscricao/inscricao-read/inscricao-read.component';
import { ConfigEditComponent } from './views/admin/configuracao/config-edit/config-edit.component';
import { OpcaoEditComponent } from './views/admin/opcao/opcao-edit/opcao-edit.component';
import { OpcaoCreateComponent } from './views/admin/opcao/opcao-create/opcao-create.component';
import { QuestaoEditComponent } from './views/admin/questao/questao-edit/questao-edit.component';
import { QuestaoCreateComponent } from './views/admin/questao/questao-create/questao-create.component';
import { QuestaoReadComponent } from './views/admin/questao/questao-read/questao-read.component';
import { QuestionarioEditComponent } from './views/admin/questionario/questionario-edit/questionario-edit.component';
import { QuestionarioCreateComponent } from './views/admin/questionario/questionario-create/questionario-create.component';
import { QuestionarioReadComponent } from './views/admin/questionario/questionario-read/questionario-read.component';
import { AdministradorCreateComponent } from './views/admin/administrador/administrador-create/administrador-create.component';
import { AdministradorReadComponent } from './views/admin/administrador/administrador-read/administrador-read.component';
import { CategoriaEditComponent } from './views/admin/categoria/categoria-edit/categoria-edit.component';
import { CategoriaCreateComponent } from './views/admin/categoria/categoria-create/categoria-create.component';
import { CategoriaReadComponent } from './views/admin/categoria/categoria-read/categoria-read.component';
import { ResponseresetAdminComponent } from './views/admin/password/responsereset-admin/responsereset-admin.component';
import { AuthadminGuard } from './guard/authadmin.guard';
import { ResponseresetComponent } from './views/site/password/responsereset/responsereset.component';
import { AuthsiteGuard } from './guard/authsite.guard';
import { PerfilComponent } from './views/site/perfil/perfil.component';
import { RegistroComponent } from './views/site/registro/registro.component';
// import { ContatoComponent } from './views/site/contato/contato.component';
import { HomeSiteComponent } from './views/site/home/home-site/home-site.component';
import { HomeAdminComponent } from './views/admin/home/home-admin/home-admin.component';
import { LoginAdminComponent } from './views/admin/login/login-admin/login-admin.component';
import { LoginSiteComponent } from './views/site/login/login-site/login-site.component';
import { QuestReadComponent } from './views/site/questionario/quest-read/quest-read.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: LoginSiteComponent,
  },
  {
    path: 'home',
    component: HomeSiteComponent,
    canActivate: [AuthsiteGuard],
  },
  {
    path: 'login',
    component: LoginSiteComponent,
  },
  {
    path: 'registro',
    component: RegistroComponent,
  },
  {
    path: 'response-password-reset',
    component: ResponseresetComponent,
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [AuthsiteGuard],
  },
  {
    path: 'meus-questionarios/:id',
    component: MeusQuestionariosReadComponent,
    canActivate: [AuthsiteGuard],
  },
  {
    path: 'meus-questionarios/show/:identificador/:questionario',
    component: MeusQuestionariosShowComponent,
    canActivate: [AuthsiteGuard],
  },
  {
    path: 'questionario/:questionarioAtivoId',
    component: QuestReadComponent,
    canActivate: [AuthsiteGuard],
  },
  {
    path: 'finalizou/:identificador',
    component: QuestFinalizouComponent,
    canActivate: [AuthsiteGuard],
  },
  /*{
    path: 'contato',
    component: ContatoComponent,
  },*/
  // ------------------------------------- ROTAS ADMIN ------------------------------------------
  {
    path: 'admin',
    component: LoginAdminComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/login',
    component: LoginAdminComponent,
  },
  {
    path: 'admin/response-password-reset',
    component: ResponseresetAdminComponent,
  },
  {
    path: 'admin/perfil',
    component: PerfilAdminComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/home',
    component: HomeAdminComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/questionarios',
    component: QuestionarioReadComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/questionarios/create',
    component: QuestionarioCreateComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/questionarios/edit/:id',
    component: QuestionarioEditComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/questoes',
    component: QuestaoReadComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/questoes/create',
    component: QuestaoCreateComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/questoes/edit/:id',
    component: QuestaoEditComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/reguas',
    component: ReguaReadComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/reguas/create',
    component: ReguaCreateComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/reguas/edit/:id',
    component: ReguaEditComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/opcoes/create/:id/questao',
    component: OpcaoCreateComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/opcoes/edit/:id',
    component: OpcaoEditComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/categorias',
    component: CategoriaReadComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/categorias/create',
    component: CategoriaCreateComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/configuracoes/edit/:id',
    component: ConfigEditComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/categorias/edit/:id',
    component: CategoriaEditComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/administradores',
    component: AdministradorReadComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/administradores/create',
    component: AdministradorCreateComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/inscricoes',
    component: InscricaoReadComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/inscricoes/quest-respondidos/show/:id',
    component: QuestRespondidoReadComponent,
    canActivate: [AuthadminGuard],
  },
  {
    path: 'admin/respostas/aluno/show/:identificador/:questionario',
    component: RespostasReadComponent,
    canActivate: [AuthadminGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
