import { ReguaService } from './../../../../services/admin/regua.service';
import { Router } from '@angular/router';
import { Regua } from './../../../../models/regua.model';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-regua-read',
  templateUrl: './regua-read.component.html',
  styleUrls: ['./regua-read.component.css']
})
export class ReguaReadComponent implements OnInit {

  public reguas: Regua[] = null;
  public erro: any = null;
  public id: any = null;
  public descricaoRegua: any = null;
  public paginaAtual = 1;

  constructor(
    private reguaService: ReguaService,
    private router: Router
  ) {}

  ngOnInit() {
    this.readReguas();
  }

  public readReguas(): void {
    this.reguaService.read().subscribe(
      (res: Regua[]) => {
        this.reguas = res;
      },
      (error: any) => {
        this.erro = error;
        this.reguaService.toastError('Erro ao buscar Réguas. Sistema indisponível, tente novamente !');
      }
    );
  }

  public modalDelete(id: string): void {
    this.id = id; // id a ser deletado
    this.showRegua(this.id);
    $('#modalDelete').modal()
  }

  public showRegua(id): void {
    this.reguaService.readById(id).subscribe(
      (res: Regua) => {
        this.descricaoRegua = res.data.descricao;     // para mostrar no modal
      },
      (error: any) => {
        this.erro = error;
        this.reguaService.toastError('Erro ao buscar Régua. Sistema indisponível, tente novamente !');
      }
    );
  }

  public confirmDelete(id: string): void {
    this.reguaService.delete(id).subscribe(
      (res: Regua) => {
        location.reload();
        this.reguaService.toastSuccess('Régua apagada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.reguaService.toastError('Erro ao apagar Régua. Tente novamente !');
      }
    );
  }
}
