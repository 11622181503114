import { Categoria } from './../../../../models/categoria.model';
import { Router } from '@angular/router';
import { CategoriaService } from './../../../../services/admin/categoria.service';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-categoria-read',
  templateUrl: './categoria-read.component.html',
  styleUrls: ['./categoria-read.component.css'],
})
export class CategoriaReadComponent implements OnInit {

  public categorias: Categoria[] = null;
  public erro: any = null;
  public id: any = null;
  public nomeCategoria: any = null;
  public paginaAtual = 1;

  constructor(
    private categoriaService: CategoriaService,
    private router: Router
  ) {}

  ngOnInit() {
    this.readCategorias();
  }

  public readCategorias(): void {
    this.categoriaService.read().subscribe(
      (res: Categoria[]) => {
        this.categorias = res;
      },
      (error: any) => {
        this.erro = error;
        this.categoriaService.toastError('Erro ao buscar Categorias. Sistema indisponível, tente novamente !');
      }
    );
  }

  public modalDelete(id: string): void {
    this.id = id; // id a ser deletado
    this.showCategoria(this.id);
    $('#modalDelete').modal()
  }

  public showCategoria(id): void {
    this.categoriaService.readById(id).subscribe(
      (res: Categoria) => {
        this.nomeCategoria = res.data.nome;     // para mostrar no modal
      },
      (error: any) => {
        this.erro = error;
        this.categoriaService.toastError('Erro ao buscar Categoria. Sistema indisponível, tente novamente !');
      }
    );
  }

  public confirmDelete(id: string): void {
    this.categoriaService.delete(id).subscribe(
      (res: Categoria) => {
        location.reload();
        this.categoriaService.toastSuccess('Categoria apagada com sucesso !');
      },
      (error: any) => {
        this.erro = error;
        this.categoriaService.toastError('Erro ao apagar Categoria. Tente novamente !');
      }
    );
  }
}
