import { Router } from '@angular/router';
import { ReguaService } from './../../../../services/admin/regua.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regua-create',
  templateUrl: './regua-create.component.html',
  styleUrls: ['./regua-create.component.css'],
})
export class ReguaCreateComponent implements OnInit {
  public reguaForm = this.fb.group({
    descricao: ['', [Validators.required, Validators.minLength(3)]],
    pontuacao_minima: ['', [Validators.required]],
    pontuacao_maxima: ['', [Validators.required]],
  });

  public erro: any = null;

  constructor(
    private fb: FormBuilder,
    private reguaService: ReguaService,
    private router: Router
  ) {}

  ngOnInit() {}

  public onSubmit(): void {
    const formData = new FormData();
    formData.append('descricao', this.reguaForm.get('descricao').value);
    formData.append(
      'pontuacao_minima',
      this.reguaForm.get('pontuacao_minima').value
    );
    formData.append(
      'pontuacao_maxima',
      this.reguaForm.get('pontuacao_maxima').value
    );

    this.reguaService.create(formData).subscribe(
      (res: any) => {
        this.router.navigate(['/admin/reguas']);
        this.reguaService.toastSuccess(
          'Cadastro de régua efetuado com sucesso !'
        );
      },
      (error: any) => {
        this.erro = error;
        this.reguaService.toastError(
          'Erro ao efetuar cadastro da régua. Tente novamente !'
        );
        this.reguaForm.reset();
      }
    );
  }

  public cancel(): void {
    this.router.navigate(['/admin/reguas']);
  }

  get descricao() {
    return this.reguaForm.get('descricao');
  }

  get pontuacao_minima() {
    return this.reguaForm.get('pontuacao_minima');
  }
  get pontuacao_maxima() {
    return this.reguaForm.get('pontuacao_maxima');
  }
}
