import { QuestionarioService } from './../../../../services/admin/questionario.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home-site.component.html',
  styleUrls: ['./home-site.component.css']
})
export class HomeSiteComponent implements OnInit {

  public questionarioAtivoId: any = null;
  public erro: any = null;

  constructor(public questionarioService: QuestionarioService) { }

  ngOnInit() {
    this.readQuestionarioAtivo();
  }

  public readQuestionarioAtivo(): void {
    this.questionarioService.questionarioAtivo().subscribe(
      (res: any) => {
        this.questionarioAtivoId = res.data.id;
      },
      (error: any) => {
        this.erro = error;
        this.questionarioService.toastError('Erro ao buscar Questionário Ativo. Sistema indisponível, tente novamente !');
      }
    );
  }
}
