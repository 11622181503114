import { User } from './../../models/user.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InscricaoService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public read(): Observable<User[]> {
    const service = 'inscricoes';
    return this.http.get<User[]>(this.baseUrlAdmin + service);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
