import { Router } from '@angular/router';
import { UserService } from './../../../../services/admin/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-administrador-create',
  templateUrl: './administrador-create.component.html',
  styleUrls: ['./administrador-create.component.css'],
})
export class AdministradorCreateComponent implements OnInit {
  public adminForm = this.fb.group({
    nome: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    emailConfirmacao: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    passwordConfirmacao: ['', [Validators.required, Validators.minLength(6)]],
  });

  public erro: any = null;
  public status = true;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {}

  public onSubmit(): void {

    this.validaAdminForm();

    if (this.status === true) {
      const formData = new FormData();
      formData.append('username', this.adminForm.get('nome').value);
      formData.append('email', this.adminForm.get('email').value);
      formData.append('password', this.adminForm.get('password').value);
      formData.append('role', '1');

      this.userService.create(formData).subscribe(
        (res: any) => {
          this.router.navigate(['/admin/administradores']);
          this.userService.toastSuccess('Novo administrador cadastrado com sucesso. Email enviado ao administrador cadastrado !');
        },
        (error: any) => {
          this.erro = error;
          this.handleError(this.erro);
          this.adminForm.reset();
          this.router.navigate(['/admin/administradores/create']);
        }
      );
    }
  }

  public handleError(erro): void {
    if (erro.status === 400) {
      this.userService.toastError('Já existe cadastro de administrador com esse email !');
    } else {
      this.userService.toastError('Erro ao efetuar registro de administrador. Tente novamente !'
      );
    }
  }

  public validaAdminForm(): void {
    if (
      this.adminForm.value.email !== this.adminForm.value.emailConfirmacao
    ) {
      this.userService.toastError('Informe o mesmo email !');
      this.status = false;
    } else {
      if (
        this.adminForm.value.password !==
        this.adminForm.value.passwordConfirmacao
      ) {
        this.userService.toastError('Informe a mesma senha !');
        this.status = false;
      }
    }
  }

  public cancel(): void {
    this.router.navigate(['/admin/administradores']);
  }

  get nome() { return this.adminForm.get('nome'); }
  get email() { return this.adminForm.get('email'); }
  get emailConfirmacao() { return this.adminForm.get('emailConfirmacao'); }
  get password() { return this.adminForm.get('password'); }
  get passwordConfirmacao() { return this.adminForm.get('passwordConfirmacao'); }
}
