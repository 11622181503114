import { Gabarito } from './../../models/gabarito.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GabaritoService {
  baseUrlAdmin = environment.baseUrlAdmin;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public questionariosRespondidos(id: string): Observable<any> {
    const service = `${'gabaritos/questionarios/aluno'}/${id}`;
    return this.http.get<any>(this.baseUrlAdmin + service);
  }

  public respostasQuestionario(identificador: string): Observable<any> {
    const service = `${'gabarito/aluno'}/${identificador}`;
    return this.http.get<any>(this.baseUrlAdmin + service);
  }

  public pontuacaoQuestionario(identificador: string): Observable<any> {
    const service = `${'gabarito/pontuacao'}/${identificador}`;
    return this.http.get<any>(this.baseUrlAdmin + service);
  }

  public pontuacaoTodosQuestionarios(): Observable<any> {
    const service = `${'gabarito/pontuacoes/todas'}`;
    return this.http.get<any>(this.baseUrlAdmin + service);
  }

  public countOpcao(id: string): Observable<Gabarito> {
    const service = `${'gabaritos/opcao'}/${id}`;
    return this.http.get<Gabarito>(this.baseUrlAdmin + service);
  }

  public contaRespostasPorQuestionario(questionarioAtivoId: any): Observable<any> {
    const service = `${'gabarito/contador'}/${questionarioAtivoId}`;
    return this.http.get<Gabarito>(this.baseUrlAdmin + service);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
