import { ToastrService } from 'ngx-toastr';
import { AuthadminService } from './../services/admin/authadmin.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthadminGuard implements CanActivate {

  constructor(
    private authAdminService: AuthadminService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  canActivate(): boolean {
    if (this.authAdminService.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/admin/login']);
      this.toastr.error('Você precisa estar logado para acessar essa área !');
      return false;
    }
  }
}
