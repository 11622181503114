import { UserService } from './../../../services/site/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
})
export class RegistroComponent implements OnInit {
  public registroForm = this.fb.group({
    nome: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    emailConfirmacao: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    passwordConfirmacao: ['', [Validators.required, Validators.minLength(6)]],
  });

  public erro: any = null;
  public status = true;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {}

  public onSubmit(): void {

    this.validaRegistroForm();

    if (this.status === true) {
      const formData = new FormData();
      formData.append('username', this.registroForm.get('nome').value);
      formData.append('email', this.registroForm.get('email').value);
      formData.append('password', this.registroForm.get('password').value);
      formData.append('role', '2');

      this.userService.create(formData).subscribe(
        (res: any) => {
          this.router.navigate(['/']);
          this.userService.toastSuccess(
            'Registro efetuado com sucesso, efetue seu login !'
          );
        },
        (error: any) => {
          this.erro = error;
          this.handleError(this.erro);
          this.registroForm.reset();
          this.router.navigate(['/registro']);
        }
      );
    }
  }

  public handleError(erro): void {
    if (erro.status === 400) {
      this.userService.toastError(
        'Já existe cadastro com esse email. Clique em Esqueci a senha !'
      );
    } else {
      this.userService.toastError(
        'Erro ao efetuar registro. Tente novamente !'
      );
    }
  }

  public cancel(): void {
    this.router.navigate(['login']);
  }

  public validaRegistroForm(): void {
    if (
      this.registroForm.value.email !== this.registroForm.value.emailConfirmacao
    ) {
      this.userService.toastError('Informe o mesmo email !');
      this.status = false;
    } else {
      if (
        this.registroForm.value.password !==
        this.registroForm.value.passwordConfirmacao
      ) {
        this.userService.toastError('Informe a mesma senha !');
        this.status = false;
      }
    }
  }

  get nome() { return this.registroForm.get('nome'); }
  get email() { return this.registroForm.get('email'); }
  get emailConfirmacao() { return this.registroForm.get('emailConfirmacao'); }
  get password() { return this.registroForm.get('password'); }
  get passwordConfirmacao() { return this.registroForm.get('passwordConfirmacao'); }
}
