import { Identificador } from './../../models/identificador.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdentificadorService {

  baseUrlSite = environment.baseUrlSite;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  public create(identificador: any): Observable<Identificador> {
    const service = 'identificador';
    return this.http.post<Identificador>(this.baseUrlSite + service, identificador);
  }

  public toastSuccess(msg: string) {
    return this.toastr.success(msg);
  }

  public toastError(msg: string) {
    return this.toastr.error(msg);
  }
}
